<template>
  <HomeApp title="Form Registrasi"/>
</template>

<script>
import HomeApp from './components/HomeApp.vue'

export default {
  name: 'App',
  components: {
    HomeApp
  }
}
</script>

<style>
#app {

}
</style>
