<template>
  
  <br>
  <div v-if="!isAuthenticated">
        <div class="row flex-center py-6 text-center">
          <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <div class="card">
              <div class="card-body p-4 p-sm-5">
  
                <h5 class="mt-3 mb-0">Hi! Archabaleno</h5><small>Enter your password to access.</small>
                <form class="mt-4 row g-0 mx-sm-4">
                  <div class="col"><input class="form-control me-2 mb-2" type="password" v-model="password" placeholder="Password" aria-label="User's password" aria-describedby="user-password" /></div>
                  <div class="col-auto ps-2"><button class="btn btn-primary px-3 mb-2" id="user-password" @click="authenticate">Login</button></div>
                  <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
                </form>
              </div>
            </div>
          </div>
        </div>
  </div>
  <div v-else>
    <div class="card mb-3">
      <div class="card-header border-bottom">
        <div class="row flex-between-end">
          <div class="col-auto align-self-center">
            <h5 class="mb-0" data-anchor="data-anchor">Data</h5>
            <p class="mb-0 pt-1 mt-2 mb-0">Anggota Temu Sesat</p>
          </div>
          <div class="col-auto ms-auto">
            <div class="nav nav-pills nav-pills-falcon flex-grow-1 mt-2" role="tablist">
              <button class="btn btn-sm" data-bs-toggle="pill" data-bs-target="#dom-733959ae-a8a2-4c3d-b4bb-e0c46e89938d" type="button" role="tab" aria-controls="dom-733959ae-a8a2-4c3d-b4bb-e0c46e89938d" aria-selected="true" id="tab-dom-733959ae-a8a2-4c3d-b4bb-e0c46e89938d">SW</button>
              <button class="btn btn-sm  active" data-bs-toggle="pill" data-bs-target="#dom-733959ae-a8a2-4c3d-b4bb-e0c46e89938d1" type="button" role="tab" aria-controls="dom-733959ae-a8a2-4c3d-b4bb-e0c46e89938d1" aria-selected="false" id="tab-dom-733959ae-a8a2-4c3d-b4bb-e0c46e89938d1">Umum</button></div>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="tab-content">
          <div class="tab-pane preview-tab-pane" role="tabpanel" aria-labelledby="tab-dom-733959ae-a8a2-4c3d-b4bb-e0c46e89938d" id="dom-733959ae-a8a2-4c3d-b4bb-e0c46e89938d">
            <div class="table-responsive scrollbar">
              <table class="table table-hover table-striped overflow-hidden">
                <thead>
                  <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">tanggal_daftar</th>
                    <th scope="col">Phone</th>
                    <th scope="col">kabkota</th>
                    <th scope="col">kecamatan</th>
                    <th scope="col">kelurahan</th>
                    <th scope="col">grup</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="align-middle" v-for="(item, index) in jsonData" :key="index">
                    <td class="text-nowrap">{{ item.no }}</td>
                    <td class="text-nowrap"><a :href="'https://dashboard.temupilih.id/canvasser/laporan/' + item.profile" target="_blank"> {{ item.name }}</a> </td>
                    <td class="text-nowrap">{{ item.tanggal }}</td>
                    <td class="text-nowrap">{{ item.phone }}</td>
                    <td class="text-nowrap">{{ item.kabkota }}</td>
                    <td class="text-nowrap">{{ item.kecamatan }}</td>
                    <td class="text-nowrap">{{ item.kelurahan }}</td>
                    <td class="text-nowrap">{{ item.grup }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tab-pane preview-tab-pane active" role="tabpanel" aria-labelledby="tab-dom-733959ae-a8a2-4c3d-b4bb-e0c46e89938d" id="dom-733959ae-a8a2-4c3d-b4bb-e0c46e89938d1">
            <div class="table-responsive scrollbar">
              <table class="table table-hover table-striped overflow-hidden">
                <thead>
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Phone</th>
                    <th scope="col">provinsi_name</th>
                    <th scope="col">kabkota_name</th>
                    <th scope="col">kecamatan_name</th>
                    <th scope="col">kelurahan_name</th>
                    <th scope="col">rt</th>
                    <th scope="col">rw</th>
                    <th scope="col">Grup</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="align-middle" v-for="(item, index) in jsonDataUmum" :key="index">
                    <td class="text-nowrap"><a :href="'https://dashboard.temupilih.id/canvasser/laporan/' + item.id" target="_blank"> {{ item.name }}</a> </td>
                    <td class="text-nowrap">{{ item.phone }}</td>
                    <td class="text-nowrap">{{ item.provinsi_name}}</td>
                    <td class="text-nowrap">{{ item.kabkota_name }}</td>
                    <td class="text-nowrap">{{ item.kecamatan_name }}</td>
                    <td class="text-nowrap">{{ item.kelurahan_name }}</td>
                    <td class="text-nowrap">{{ item.rt }}</td>
                    <td class="text-nowrap">{{ item.rw }}</td>
                    <td class="text-nowrap">{{ item.jabatan }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      jsonData: [],
      jsonDataUmum: [],
      isAuthenticated: false,
      password: '',
      errorMessage: '',
      correctPassword: 'qwerty123'
    };
  },
  methods: {
    authenticate() {
      if (this.password === this.correctPassword) {
        this.isAuthenticated = true;
        this.errorMessage = '';
        // Fetch data jika password benar
        this.fetchData();
        this.fetchDataUmum();
      } else {
        this.errorMessage = 'Password salah!';
      }
    },
    fetchData() {
      fetch('/data.json')
        .then(response => response.json())
        .then(data => {
          this.jsonData = data;
        })
        .catch(error => console.error('Error fetching JSON:', error));
    },
    fetchDataUmum() {
      fetch('/data-umum.json')
        .then(response => response.json())
        .then(data => {
          this.jsonDataUmum = data;
        })
        .catch(error => console.error('Error fetching JSON:', error));
    }
  }
};
</script>

<style scoped>
  .success-animation { margin:50px auto;}

.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position:relative;
    top: 5px;
    right: 5px;
   margin: 0 auto;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4bb71b;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
 
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}
</style>
